<template>
  <div>
    <v-card-title class="headline">
      Admin Events Finder
      <v-spacer/>
      <v-text-field v-model="query" solo rounded clearable hide-details v-on:keyup.enter="search" @click:clear="search='';search()" @click:prepend="search" prepend-icon="fa-search" class="search-box" placeholder="Enter Search Query" />
    </v-card-title>

    <v-alert v-if="error" type="error">{{error}}</v-alert>
    <v-alert v-if="response && (response.status == 404 || !events)" type="warning">No data found (or no permissions to view).</v-alert>
    <v-container v-if="events">
      <EventGrid :items="events" :routeResolver="resolveEventManagerRoute" class="mx-4 mb-4"></EventGrid>
    </v-container>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import adminService from "@/services/adminService";
import { EventBus } from '@/plugins/eventbus.js';
import EventGrid from "@/components/EventGrid.vue";

export default {
  name: "AdminEvents",
  components: {
    EventGrid,
  },
  props: {
  },
  data() {
    return {
      query: null,
      response: null,
      events: null,
      error: null,
    };
  },
  async mounted() {
    this.query = this.$route.query.search;

    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {

    async search() {
      await this.getProfile();
    },
    async getProfile() {
      this.profile = null;
      this.response = null;
      if (this.user && this.query) {
        adminService.impersonationProfileId = this.profileId;
        try {
          this.response = await adminService.findEvents(this.query);
          this.events = this.response.data.events.data;
        }
        catch (ex) {
          console.error(ex);
          this.error = "Error getting data information. Possibly no permissions."
        }
      }
    },
    resolveEventManagerRoute(eventId) {
      return {name: 'adminEventDetails', params: {id: eventId}};
    },


  },
  computed: {
    
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">

.search-box {
  max-width: 350px;
  float: right;
}
</style>

